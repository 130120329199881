<template>
  <div>
    <app-breadcrumb/>
    <div class="page-container transfer-apply">
      <div class="pane-title">基本信息</div>
      <el-divider></el-divider>
      <el-alert title="带红色星号“*”部分为必填项" type="info" style="margin-bottom:10px;"/>
      <el-form ref="applyForm" :model="applyForm" :rules="rules" label-width="140px" size="small">
        <el-form-item label="账户类型:" prop="acType" style="margin-bottom:10px;">
          <el-select v-model="applyForm.acType" placeholder="请选择账户类型" style="width: 280px">
            <el-option v-for="item in accounts" :key="item.code" :label="$lt(initDict.acType, item.code)"
                       :value="item.code"/>
          </el-select>
        </el-form-item>
        <el-form-item label="余额:" style="margin-bottom:10px;">
          <span class="money">{{ balance && balance.toFixed(2) }}</span>
        </el-form-item>
        <el-form-item label="收款方用户编号:" prop="userCode">
          <el-input style="width: 280px" v-model.trim="applyForm.userCode" clearable @change="handleUserCode"/>
          <span style="color: red">&nbsp;&nbsp;&nbsp;&nbsp;{{ userCodespan }}</span>
        </el-form-item>
        <el-form-item label="付款金额:" prop="money">
          <el-input v-model.trim="applyForm.money" placeholder="请填写付款金额" style="width: 280px"/>
        </el-form-item>
        <el-form-item label="支付密码:" prop="password">
          <el-input v-model.trim="applyForm.password" placeholder="请填写您的支付密码" show-password
                    style="width: 280px"/>
        </el-form-item>
        <el-form-item v-show="sendShow" label="短信验证码:" prop="captcha">
          <el-input v-model.trim="applyForm.captcha" placeholder="短信验证码" style="width: 200px"/>
          <el-button :loading="loading" :disabled="sendDisabled" type="info" @click="sendSms" style="margin-left: 10px">
            {{ codeTxt }}
          </el-button>
        </el-form-item>
        <el-form-item v-show="this.userCode==='ALA88882020'" label="公司收款账户:" prop="accountId">
          <el-select
              v-model="applyForm.accountId" placeholder="请选择公司收款账户" style="width: 280px">
            <el-option v-for="item in accountList" :key="item.id" :label="item.bankName+' '+item.accountNo"
                       :value="item.accountId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="applyForm.remark" type="textarea" :rows="4" placeholder="请填写备注" maxlength="100"
                    show-word-limit style="width: 640px"/>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" type="primary" @click="onSubmit">保存</el-button>
          <el-button type="default" @click="onBack">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {fetchBalance} from '@/api/account'
import {applyTransfer, search1} from '@/api/account/transfer'
import {checkTransCode, fetchBankAccounts, transCode} from "@/api/account/recharge"

export default {
  name: 'AccountTransferApply',
  data() {
    return {
      accountList: [],
      codeTxt: '发送验证码',
      userCodespan: '',
      sendShow: false,
      sendDisabled: false,
      loading: false,
      applyForm: {
        acType: 'F$',
        userCode: undefined,
        money: undefined,
        password: undefined,
        accountId: undefined,
        captcha: undefined,
        remark: undefined
      },
      balance: undefined,
      balances: {},
      rules: {
        acType: [{required: true, message: '请选择账户类型', trigger: 'blur'}],
        userCode: [{required: true, message: '请填写收款方用户编号', trigger: 'blur'}],
        accountId: [{required: true, message: '请选择', trigger: 'blur'}],
        money: [
          {required: true, message: '请填写转账金额', trigger: 'blur'},
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请填写正确的格式，可保留两位小数',
            trigger: 'blur'
          }
        ],
        password: [{required: true, message: '请填写支付密码', trigger: 'blur'}]
      },
      initDict: {
        acType: 'fiacinput.actype.cn'
      }
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'isAgent'
    ]),
    accounts() {
      return this.$ll(this.initDict.acType).filter(ele => ele.code === 'F$' || ele.code === 'H0')
    }
  },
  watch: {
    'applyForm.userCode': function (val) {
      this.applyForm.userCode = val.toUpperCase()
    },
    applyForm: {
      handler(newVal) {
        this.balance = this.balances[newVal.acType] || 0
      },
      deep: true
    }
  },
  mounted() {
    this.getBankAccounts()
    this.getBalance()
    this.checkTransCode()
  },
  methods: {
    checkTransCode() {
      this.loading = true
      checkTransCode({memberNo: this.userCode}).then((res) => {
        this.sendShow = res.data === '0'
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    },
    sendSms() {
      this.$confirm('确定发送验证码?', '发送验证码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        this.loading = true
        this.sendDisabled = true
        transCode().then(() => {
          let time = 60
          this.codeTxt = time
          this.timer = setInterval(() => {
            if (time === 1) {
              this.codeTxt = "发送验证码"
              this.sendDisabled = false
              clearInterval(this.timer)
            } else {
              time--
              this.codeTxt = time
            }
          }, 1000)
          this.loading = false
        }).catch(err => {
          this.sendDisabled = false
          console.warn(err)
          this.loading = false
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '操作取消'
        })
      })

    },
    getBankAccounts() {
      fetchBankAccounts().then(res => {
        this.accountList = res.data
      })
    },
    getBalance() {
      fetchBalance({acType: 'F$'}).then(res => {
        this.balances['F$'] = res.data
        this.balance = res.data
      })
      fetchBalance({acType: 'H0'}).then(res => {
        this.balances['H0'] = res.data
      })
      if (!this.isAgent) {
        fetchBalance({acType: 'NV'}).then(res => {
          this.balances['NV'] = res.data
        })
      }
    },
    onBack() {
      this.$router.go(-1)
    },
    onSubmit() {
      if (this.userCode === 'CNA88882020' || this.userCode === 'CNA88882021' || this.userCode === 'CNA88882022') {
        if (!this.applyForm.accountId) {
          this.$message.error(this.$t('必须选择公司收款账户!'))
          return
        }
      } else {
        this.applyForm.accountId = 'none'
      }
      this.$refs['applyForm'].validate((valid) => {
        if (valid) {
          if (this.applyForm.userCode === this.userCode) {
            // 不能转账给自己
            this.$message.error(this.$t('cannot.trans.to.myself'))
            return
          }
          this.$confirm('确定转账?', '转账', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success'
          }).then(() => {
            this.loading = true
            applyTransfer(this.applyForm).then((res) => {
              if (res.success) {
                this.$notify({
                  title: '成功',
                  message: '申请转账成功!',
                  type: 'success'
                })
              }
              this.$router.replace('/account/transfer')
              this.loading = false
            }).catch(err => {
              console.warn(err)
              this.loading = false
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '操作取消'
            })
          })
        }
      })
    },
    handleUserCode(val) {
      if (val) {
        this.applyForm.userCode = val.trim().toUpperCase()
        search1({userCode: val.trim().toUpperCase()}).then(res => {
          this.userCodespan = res.data[0].name
        }).catch(() => {
          this.userCodespan = '请输入正确的收款方用户编号'
        })
      } else {
        this.userCodespan = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transfer-apply {
  .money {
    color: green;
    font-size: 16px;
  }
}
</style>
